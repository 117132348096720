import styled from "styled-components";

const SocialMediasContainer = styled.ul`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin: 10px;
  min-width: 100%;
`;
export default SocialMediasContainer;
